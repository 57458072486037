<template>
  <div class="row content-wrapper">
    <h1 class="auth-title">로그인</h1>
    <div class="col-md-12 auth-wrapper">
      <form class="form" @submit.prevent="submitForm">
        <div class="mb-3">
          <label for="email" class="form-label">이메일</label>
          <input
            type="email"
            class="form-control"
            id="email"
            v-model="email"
            placeholder="이메일을 입력해주세요."
          />
        </div>
        <div class="mb-5">
          <label for="password" class="form-label">비밀번호</label>
          <input
            type="password"
            class="form-control"
            id="password"
            v-model="password"
            placeholder="영문+숫자 10자 이상으로 입력해주세요."
          />
        </div>
        <div class="mb-5 text-center">
          <button
            :disabled="!isEmailValid || !password"
            type="submit"
            class="btn btn-default auth-submit-btn"
          >
            로그인
          </button>
        </div>
      </form>
      <!-- <button @click="useAuthProvider('facebook', null)">
        oAuth Facebook M1
      </button> -->
    </div>
    <div class="col-md-12 text-center">
      <span class="auth-link-text">아직 제주투브이알 회원이 아니신가요?</span>
      <router-link to="/register" class="auth-link">회원가입</router-link>
    </div>
    <div class="col-md-12 text-center">
      <span class="auth-link-text">비밀번호를 잊으셨나요?</span>
      <router-link to="/forgot-password" class="auth-link"
        >비밀번호찾기</router-link
      >
    </div>
  </div>
</template>

<script>
import { validateEmail } from '../../utils/validation.js';
// import { Providers } from 'universal-social-auth';

export default {
  data() {
    return {
      email: '',
      password: '',
      emailErrorLog: '',
      passwordErrorLog: '',
      responseData: {},
      hash: '',
      data: {},
      fauth: false,
    };
  },
  computed: {
    isEmailValid() {
      return validateEmail(this.email);
    },
  },
  methods: {
    async submitForm() {
      try {
        const userData = {
          email: this.email,
          password: this.password,
        };

        await this.$store.dispatch('LOGIN', userData);
        this.$router.push('/');
        this.$toast.success(`${this.email}님 어서오세요`);
      } catch (error) {
        this.$toast.error(
          '존재하지 않는 이메일이거나 비밀번호가 일치하지 않습니다.'
        );
      }
    },
  },
};
</script>

<style scoped>
.auth-title {
  margin: 0;
  font-size: 30px;
  color: #333;
}
.auth-wrapper {
  padding-top: 40px;
}
.form-label {
  font-size: 18px;
  color: #333;
}
.auth-submit-btn {
  width: 250px;
  height: 60px;
  border-radius: 3px;
  background-color: #333;
  color: #fff;
}
.auth-link-text {
  padding-right: 10px;
  font-size: 16px;
}
.auth-link {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  text-decoration: underline !important;
}
</style>
