<template>
  <LoginComponent />
</template>

<script>
import LoginComponent from '@/components/auth/LoginComponent.vue';

export default {
  components: {
    LoginComponent,
  },
};
</script>

<style></style>
